import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RowCol = _resolveComponent("RowCol")!

  return (_ctx.col)
    ? (_openBlock(), _createBlock(_component_RowCol, {
        key: 0,
        label: _ctx.name,
        value: _ctx.breakdown.name
      }, null, 8, ["label", "value"]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createTextVNode(_toDisplayString(_ctx.breakdown.name) + "} ", 1)
      ], 64))
}