import {defineStore} from "pinia";
import NoteService from "@/core/services/NoteService";

export const useNoteStore = defineStore('noteStore',{
  state: ()=>({
    page: <any> {
      data: [],
      total: 0,
      totalPages: 0,
    }
  }),
  actions: {
    list(filter) {
      NoteService.findAll<any>(filter).then(res=>{
        this.page  = res
      })
    }
  }

})