
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "TaskCompleteStatus",
  props: {
    status: {},
    noneBadge: {default: false},
    showNotSet: {default: false},
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const buildLabel = (code: any) => {
      switch (code) {
        case 'DONE':
          data.value = {name: 'Done', clazz: 'success'}
          break
        case 'NOT_REQUIRED':
          data.value = {name: 'Not required', clazz: 'info'}
          break
        case 'NOT_SET':
          if (props.showNotSet) {
            data.value = {name: 'Scheduled', clazz: 'primary'}
          } else {
            data.value = {name: '', clazz: 'primary'}
          }
          break
        case 'SKIPPED':
          data.value = {name: 'Skipped', clazz: 'dark'}
          break
      }
    }
    buildLabel(props.status);
    watch(() => props.status, (cb) => {
      buildLabel(cb);
    })

    return {
      data
    }
  }
})
