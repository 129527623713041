
import {computed, defineComponent, watch} from "vue";
import {useTimerStore} from "@/store/timer.store";
import {useStopwatch} from "vue-timer-hook";
import Timer from "@/components/base/timer/Timer.vue";

export default defineComponent({
  name: "CurrentTimer",
  components: {Timer},
  props: {
    clazz: {type: String},
  },
  setup() {
    const store = useTimerStore();
    const currentTimer = computed<any>(() => store.currentTimer);
    const stopwatch = useStopwatch(0, false);
    const build = (timer) => {
      const startTime = new Date(timer.startTime)
      console.log(startTime)
      const secondBetweenTwoDate = Math.abs((new Date().getTime() - startTime.getTime()) / 1000);
      stopwatch.reset(secondBetweenTwoDate, true)
    }

    watch(currentTimer, (cb) => {
      if (cb && cb.id && cb.status === 'RUNNING') {
        build(cb)
      } else {
        stopwatch.reset(0, false);
      }
    })
    if (currentTimer.value && currentTimer.value.id && currentTimer.value.status === 'RUNNING') {
      build(currentTimer.value);
    }
    return {
      stopwatch,
    }
  }
})
