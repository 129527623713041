
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {getCurrentSpaceAccess, GetSpaceId, TeamSelect, TemplateSelect} from "@/core/composite/composite";
import Froala from "@/components/base/editor/Froala.vue";
import TaskService from "@/core/services/TaskService";
import * as Yup from "yup";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import TimerService from "@/core/services/TimerService";
import {useTimerStore} from "@/store/timer.store";
import UserListSelect from "@/components/common/UserListSelect.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import BaseField from "@/components/base/form/field/BaseField.vue";
import HelpTitle from "@/components/base/HelpTitle.vue";

export default defineComponent({
  name: "TaskCreateForm",
  components: {
    HelpTitle,
    BaseField,
    DateTimeSelect, UserListSelect, BaseRadio, Froala, BaseSelect, FormErrorMessage, BaseForm, Field
  },
  props: {
    data: {type: Object}
  },
  setup(props) {
    const templateSelect = ref('');
    const submitting = ref(false);
    const model = ref<any>(props.data)
    const timerStore = useTimerStore();
    const validate = Yup.object({
      name: Yup.string().required().label("Name"),
    });
    watch(() => props.data, cb => {
      model.value = cb;
      templateSelect.value = ''
    })
    return {
      templateSelect,
      ...GetSpaceId(),
      model,
      submitting,
      validate,
      timerStore,
      ...TeamSelect(),
      ...TemplateSelect(),
      ...getCurrentSpaceAccess(),
    }
  },
  emits: ['save', 'cancel'],
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onReset() {
      this.model.name = ''
      this.model.inputs = []
      this.model.results = []
      this.model.description = ''
      this.model.dueDate = new Date().getTime()
      this.model.assign = 'MY_SELF'
      this.model.ownerIdRequest = ''
      this.model.teamIdRequest = ''
      this.model.approvalStatus = 'NOT_REQUIRED'
      this.model.approvalIdRequest = ''
      this.model.docIdRequest = ''
      const select = this.$refs.templateSelectRef as typeof BaseSelect;
      select.onReset();
    },

    templateSelected(id) {
      const template = this.templates.find(item => item.id === id);
      this.model.name = template.name;
      this.model.inputs = template.inputs.map(item => ({
        id: item.paramId,
        name: item.name,
        value: '',
        type: item.type,
        required: item.required,
        breakIdRequest: item.breakdownId
      }))
      if (template.approvalRequired) {
        this.model.approvalStatus = 'REQUIRED';
        if (template.approvalType === 'USER') {
          this.model.approvalIdRequest = template.approvalId;
        }
      } else {
        this.model.approvalStatus = 'NOT_REQUIRED'
      }
      this.model.results = template.results.map(item => ({
        name: item.name,
        value: '',
        type: item.type,
        required: item.required,
        breakIdRequest: item.breakdownId,
        id: item.paramId
      }))
      if (template) {
        this.model.assign = template.assignType;
        if (template.assignType === 'TEAM') {
          this.model.teamIdRequest = template.assignedId
        }
        if (template.assignType === 'USER') {
          this.model.ownerIdRequest = template.assignedId
        }

        this.model.docIdRequest = template.docId;
      } else {
        this.model.docIdRequest = ''
      }

    },
    onSaveAndStart() {
      this.model.saveAndStart = true
      const form = this?.$refs?.taskCreateFormRef as typeof BaseForm;
      form.baseFormSubmitButton.click()
    },
    onSave() {
      this.submitting = true;
      TaskService.create<any>(this.model).then(res => {
        if (res.saveAndStart) {
          const model = {objectId: res.id, objectType: 'TASK', name: res.name}
          TimerService.create(model).then(res => {
            this.timerStore.setCurrenTimer(res);
          })
        }
        this.$emit('save', res)
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
