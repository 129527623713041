
import {defineComponent} from "vue";
import BreakdownValue from "@/components/base/form/field/BreakdownValue.vue";
import RowCol from "@/components/base/common/RowCol.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import FileFiledValue from "@/components/base/form/field/FileFiledValue.vue";

export default defineComponent({
  name: "FieldFormat",
  components: {FileFiledValue, CurrencyFormat, RowCol, BreakdownValue},
  props: {
    field: {}
  }
})
