import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createBlock(_component_el_pagination, {
    "current-page": _ctx.pagination.page,
    "onUpdate:current-page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination.page) = $event)),
    onCurrentChange: _ctx.currentPageChange,
    "page-size": _ctx.pagination.rowsPerPage,
    layout: "prev, pager, next",
    total: _ctx.pagination.total,
    "hide-on-single-page": true,
    background: ""
  }, null, 8, ["current-page", "onCurrentChange", "page-size", "total"]))
}