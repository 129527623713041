
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "Duration",
  props: {
    duration: {type: Number, default: () => 0},
    isMinute: {type: Boolean, default: false},
  },
  setup(props) {
    const format = ref('00:00');
    const load = (total) => {
      const min = props.isMinute ? total : Math.floor(total / 60);
      const hours = Math.floor(min / 60);
      const minutes = min % 60;
      format.value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
    load(props.duration)
    watch(() => props.duration, cb => {
      load(cb)
    })
    return {
      format
    }
  },
})
