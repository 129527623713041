
import {defineComponent, ref, watch} from "vue";
import IdService from "@/core/services/IdService";

export default defineComponent({
  name: "DecodeId",
  props: {
    id: {type: String}
  },
  setup(props) {
    const idDecode = ref('');
    const decodeId = (raw) => {
      IdService.decode(raw).then(res => {
        idDecode.value = res;
      })
    }
    decodeId(props.id)
    watch(() => props.id, (cb) => {
      decodeId(cb);
    })
    return {
      idDecode
    }
  }
})
