import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title flex-column" }
const _hoisted_4 = { class: "text-muted fw-bold fs-7" }
const _hoisted_5 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metric_name = _resolveComponent("metric-name")!
  const _component_MetricChart = _resolveComponent("MetricChart")!
  const _component_MetricValues = _resolveComponent("MetricValues")!

  return (_ctx.data?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, [
            _createVNode(_component_metric_name, {
              metric: _ctx.data,
              redirect: true
            }, null, 8, ["metric"]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.data.description), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_MetricChart, {
            "metric-id": _ctx.data.id
          }, null, 8, ["metric-id"])
        ]),
        _createVNode(_component_MetricValues, {
          "metric-id": _ctx.data.id,
          "team-metric": _ctx.data?.team?.id,
          size: 10
        }, null, 8, ["metric-id", "team-metric"])
      ]))
    : _createCommentVNode("", true)
}