
import {defineComponent, PropType} from "vue";
import {GetSpaceId, LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "MetricName",
  props: {
    metric: {type: Object as PropType<any>},
    redirect: {type: Boolean, default: false},
  },
  setup() {
    return {
      ...GetSpaceId(),
      ...LoadPanel(),
    }
  }
})
