import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.noneBadge)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(["badge me-auto fw-bolder", `badge-light-`+_ctx.data.clazz])
      }, _toDisplayString(_ctx.data.name), 3))
    : (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: _normalizeClass(["badge me-auto fw-boldest", `badge-light-`+_ctx.data.clazz])
      }, _toDisplayString(_ctx.data.name), 3))
}