import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionLogCards = _resolveComponent("ActionLogCards")!

  return (_openBlock(), _createBlock(_component_ActionLogCards, {
    total: _ctx.page?.logs?.total,
    "card-data": _ctx.page?.logs?.data,
    "current-page": _ctx.filter.paginationPage,
    "rows-per-page": _ctx.filter.paginationSize,
    onCurrentChange: _ctx.handleUpdatePage
  }, null, 8, ["total", "card-data", "current-page", "rows-per-page", "onCurrentChange"]))
}