import {BaseService} from "@/core/services/BaseService";
import {generateString} from "@/core/helpers/functions";

class WorkService extends BaseService<any> {
  endpoint = "/work"

  async updateStatus(id, status) {
    return await this.http().patch(this.endpoint + "/" + id + "/status?status=" + status).then(res => {
      return res.data
    })
  }

  async getWeekly(start, end, params) {
    const stringParams = generateString(params)
    return this.http().get(this.endpoint + "/weekly?startTime=" + start + "&endTime=" + end + stringParams).then(res => {
      return res.data;
    })
  }
}

export default new WorkService();