import {BaseService} from "@/core/services/BaseService";
import {generateString} from "@/core/helpers/functions";

class TimerService extends BaseService<any> {
  endpoint = "/timer";

  async getCurrent() {
    return await this.http().get(this.endpoint + "/current").then(res => {
      return res.data;
    })
  }

  async complete(id) {
    return await this.http().put(this.endpoint + "/" + id + "/complete").then(res => {
      return res.data;
    })
  }

  async total(filter) {
    return await this.http().get(this.endpoint + "/total?" + generateString(filter)).then(res => {
      return res.data;
    })
  }

  async chart(filter) {
    return await this.http().get(this.endpoint + "/chart?" + generateString(filter)).then(res => {
      return res.data;
    })
  }

  async addNew(data) {

    return await this.http().post(this.endpoint + "/add", data).then(res => {
      return res.data;
    })
  }

  async totalByObject(objectType, objectId) {
    return await this.http().get(this.endpoint + "/total/" + objectType + "/" + objectId).then(res => {
      return res.data
    })
  }
}


export default new TimerService()