import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Row = _resolveComponent("Row")!
  const _component_row = _resolveComponent("row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Row, {
      row: [{name:'Name',value:_ctx.data.name},{}]
    }, null, 8, ["row"]),
    _createVNode(_component_Row, {
      row: [{name:'Assigned', value: _ctx.data?.assigned, type:'USER'},{name:'Schedule', value: _ctx.data?.schedule?.frequency}]
    }, null, 8, ["row"]),
    _createVNode(_component_row, {
      row: [{name:'Next Task', value: _ctx.data?.nextTaskDate, type:'DATE'},{}]
    }, null, 8, ["row"]),
    _createElementVNode("div", null, _toDisplayString(_ctx.data.description), 1)
  ], 64))
}