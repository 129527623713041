
import {defineComponent, ref} from "vue";
import DropZoneUpload from "@/components/base/upload/DropZoneUpload.vue";
import BaseButton from "@/components/base/form/BaseButton.vue";
import DocumentService from "@/core/services/DocumentService";

export default defineComponent({
  name: "FileField",
  components: {BaseButton, DropZoneUpload},
  props: {
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup() {
    const submitting = ref(false);
    const showDropzone = ref(false);
    const fileName = ref('');
    return {
      submitting,
      showDropzone,
      fileName,
    }
  },
  methods: {
    onFileSelected(file) {
      const data = {file: file}
      this.submitting = true
      DocumentService.upload(data).then(res => {
        this.fileName = res.name
        this.$emit('update:modelValue', res.id);
        this.showDropzone = false
      }).finally(() => {
        this.submitting = false
      })
    }
  }
})
