import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_Row = _resolveComponent("Row")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_EntityLoading, { key: 0 }))
    : (_openBlock(), _createBlock(_component_Row, {
        key: 1,
        row: [{name:'Date', value: _ctx.data.date, type:'DATE'}, {name:'Reporter' ,value: _ctx.data.reportBy, type:'USER'}]
      }, null, 8, ["row"]))
}