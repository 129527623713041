import {BaseService} from "@/core/services/BaseService";

class IdService extends BaseService<any> {
  endpoint = "/hash"

  async decode(id) {
    return await this.http().get(this.endpoint  + "/decode/" +id).then(res=>{
      return res.data
    })
  }
}
export default new IdService()