import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-center flex-column-auto mb-4" }
const _hoisted_2 = { class: "fv-row w-lg-400px text-lg-center fv-plugins-icon-container fv-plugins-bootstrap5-row-valid" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "d-flex align-items-center me-2" }
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "fw-bolder fs-6" }
const _hoisted_7 = {
  key: 0,
  class: "bullet bullet-dot bg-primary h-20px w-20px me-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workspaces, (wp) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: wp.id
        }, [
          (_ctx.checkWorkspace(wp.group))
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                class: "d-flex flex-stack mb-2 cursor-pointer border-hovered border border-3 p-5 text-hover-inverse-secondary bg-hover-secondary",
                onClick: ($event: any) => (_ctx.selectedWorkspace(wp.id))
              }, [
                _createElementVNode("span", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(wp.name), 1)
                  ])
                ]),
                (_ctx.user.currentWorkspaceId ===wp.id)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_3))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}