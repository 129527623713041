import {BaseService} from "@/core/services/BaseService";

class DocUserService extends BaseService<any> {
  endpoint = '/doc-user'

  async check(id): Promise<any> {
    return await this.http().get(this.endpoint + "/status/" + id).then(res => {
      return res.data
    })
  }

  async countProgress(id) {
    return await this.http().get(this.endpoint + "/progress/" + id).then(res => {
      return res.data
    })
  }

  async complete(id) {
    return await this.http().get(this.endpoint + "/completed/" + id).then(res => {
      return res.data
    })
  }

  async start(id) {
    return await this.http().get(this.endpoint + "/start/" + id).then(res => {
      return res.data
    })
  }

  async getDocs(id) {
    return await this.http().get(this.endpoint + "/" + id + "/documents").then(res => {
      return res.data
    })
  }
}

export default new DocUserService()