import {defineStore} from "pinia";
import MessageService from "@/core/services/MessageService";

export const useMessageStore = defineStore('messageStore', {
  state: () => ({
    loading: <boolean>false,
    page: <any>{
      total: 0,
      data: [],
      totalPages: 0,
    }
  }),
  actions: {
    list(filter) {
      this.loading = true;
      MessageService.findAll(filter).then(res => {
        this.page = res;
      }).finally(() => {
        this.loading = false;
      })
    }
  }
})