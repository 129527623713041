import {BaseService} from "@/core/services/BaseService";

class HomeService extends BaseService<any> {
  endpoint = "/home"

  async getTodayWork() {
    return await this.http().get(this.endpoint + "/my-work").then(res => {
      return res.data
    })
  }
}

export default new HomeService();