
import {defineComponent, ref} from "vue";
import WorkService from "../../core/services/WorkService";
import Row from "@/components/base/common/Row.vue";

export default defineComponent({
  name: "WorkOverview",
  components: {Row},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({});
    const loading = ref(false);
    const loadTask = (id) => {
      loading.value = true;
      WorkService.get(id).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false
      })
    }
    loadTask(props.id)
    return {
      data,
      loading,
    }
  }
})
