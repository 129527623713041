
import {defineComponent, ref, watch} from "vue";
import DocumentService from "@/core/services/DocumentService";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "FileFiledValue",
  props: {
    fileId: {}
  },
  setup(props) {
    const file = ref<any>({})
    const loadFile = (id) => {
      if (id) {
        DocumentService.get<any>(id).then(res => {
          file.value = res;
        })
      } else {
        file.value = {};
      }
    }
    loadFile(props.fileId);
    watch(() => props.fileId, cb => {
      loadFile(cb);
    })
    return {
      ...LoadPanel(),
      file,
    }
  }
})
