
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import Froala from "@/components/base/editor/Froala.vue";
import TaskService from "@/core/services/TaskService";
import BaseField from "@/components/base/form/field/BaseField.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import {GetSpaceId, TeamSelect} from "@/core/composite/composite";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "TaskEditForm",
  components: {
    BaseSelect,
    UserListSelect, BaseRadio, DateTimeSelect, BaseField, Froala, FormErrorMessage, BaseForm, Field
  },
  props: {
    id: {type: String, required: true},
    data: {type: Object}
  },
  emits: ['save'],
  setup(props) {
    const submitting = ref(false)
    const model = ref(props.data);
    watch(() => props.data, (cb) => {
      model.value = cb;
    })
    return {
      submitting,
      model,
      ...TeamSelect(),
      ...GetSpaceId(),
    }
  },
  methods: {
    onSave() {
      this.submitting = false
      TaskService.update(this.id, this.model).then(() => {
        this.$emit('save')
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
