import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_UserLink = _resolveComponent("UserLink")!
  const _component_TrainingUserStatus = _resolveComponent("TrainingUserStatus")!
  const _component_Row = _resolveComponent("Row")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_EntityLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_Row, { row: [{name :'User', slot:true, key:'user'},{name:'Status', key:'status', slot:true}] }, {
          user: _withCtx(() => [
            _createVNode(_component_UserLink, {
              user: _ctx.data.user
            }, null, 8, ["user"])
          ]),
          status: _withCtx(() => [
            _createVNode(_component_TrainingUserStatus, {
              status: _ctx.data.status
            }, null, 8, ["status"])
          ]),
          _: 1
        }),
        _createVNode(_component_Row, {
          row: [{name:'Start Date', value: _ctx.data.startDate, type:'DATE'}, {name:'Completed Date', value: _ctx.data.completedDate, type:'DATE'}]
        }, null, 8, ["row"])
      ], 64))
}