
import {computed, defineComponent, ref} from "vue";
import {LoadPanel, PinaFilterObjects} from "@/core/composite/composite";
import {useTaskStore} from "@/store/task.store";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TaskName from "@/views/task/TaskName.vue";
import TaskDueDate from "@/views/work/TaskDueDate.vue";
import TaskCompleteStatus from "@/views/task/TaskCompleteStatus.vue";

export default defineComponent({
  name: "WorkTasks",
  components: {TaskCompleteStatus, TaskDueDate, TaskName, DateTimeFormat,  KtDatatable},
  props: {workId: {}},
  setup(props) {
    const filterObject = ref({workId: props.workId, isDelegateTask: false})
    const taskStore = useTaskStore();
    const page = computed(() => taskStore.pageSecond);
    const headers = [
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },
      {
        name: 'Status',
        key: 'status',
        sortable: false,
      },
      {
        name: 'Due Date',
        key: 'dueDate',
        sortable: false
      },
      {
        name: 'Completed Date',
        key: 'completedDt',
        sortable: false
      },
    ]
    return {
      headers,
      filterObject,
      page,
      ...LoadPanel(),
      ...PinaFilterObjects(taskStore, filterObject.value, [], '', true)
    }
  },
  watch: {
    workId(cb) {
      this.filterObject.workId = cb;
      this.updateFilterObject(this.filterObject);
    }
  }
})
