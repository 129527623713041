
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {usePanelStore} from "@/store/panel.store";
import TaskService from "../../core/services/TaskService";
import Row from "@/components/base/common/Row.vue";
import Swal from "sweetalert2";
import {useTaskStore} from "@/store/task.store";
import {useTimerStore} from "@/store/timer.store";
import TimerService from "@/core/services/TimerService";
import Duration from "@/views/timer/Duration.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import TaskEditForm from "@/views/task/TaskEditForm.vue";
import TaskTimers from "@/views/timer/TaskTimers.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseButton from "@/components/base/form/BaseButton.vue";
import Froala from "@/components/base/editor/Froala.vue";
import BaseField from "@/components/base/form/field/BaseField.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import AmountField from "@/components/base/form/field/AmountField.vue";
import {getCurrentSpaceAccess, LoadPanel} from "@/core/composite/composite";
import Notes from "@/views/note/Notes.vue";
import FormNote from "@/views/note/FormNote.vue";
import ApprovalStatus from "@/views/task/ApprovalStatus.vue";
import TaskApproveForm from "@/views/task/TaskApproveForm.vue";
import FieldFormat from "@/components/base/form/format/FieldFormat.vue";
import DocLink from "@/views/doc/DocLink.vue";
import {useHomePageStore} from "@/store/homepage.store";
import TaskCompleteStatus from "@/views/task/TaskCompleteStatus.vue";
import TaskDueDate from "@/views/work/TaskDueDate.vue";

export default defineComponent({
  name: "TaskPanel",
  components: {
    TaskDueDate,
    TaskCompleteStatus,
    DocLink,
    FieldFormat,
    TaskApproveForm,
    ApprovalStatus,
    FormNote,
    Notes,
    AmountField,
    BaseRadio,
    BaseField,
    Froala,
    BaseButton,
    FormErrorMessage,
    BaseForm,
    TaskTimers,
    TaskEditForm,
    BaseModal,
    QuickAction,
    Duration,
    Row,
  },
  props: {
    id: {}
  },
  setup(props) {

    const taskReq = ref<any>({
      name: '',
      inputs: [],
      results: [],
      description: '',
      dueDate: '',
      assign: 'USER',
      ownerIdRequest: '',
      teamIdRequest: '',
      approvalStatus: 'NOT_REQUIRED',
      approvalIdRequest: ''
    });
    const noteState = ref<any>({description: '', taskIdRequest: ''});
    const buttonSubmitting = ref<any>(false);
    const state = ref({results: [], submitting: false, comments: '', completeStatus: 'NOT_SET', duration: 0})
    const completeValidate = ref({});
    const taskDuration = ref(0);
    const panelStore = usePanelStore();
    const timerStore = useTimerStore();
    const currentTimer = computed(() => timerStore.currentTimer);
    const data = ref<any>({})
    const taskStore = useTaskStore()
    const homePageStore = useHomePageStore();
    const loadTaskDuration = (id) => {
      TimerService.totalByObject('TASK', id).then(res => {
        taskDuration.value = res.total;
      })
    }
    const loadData = async (id) => {
      return await TaskService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      loadTaskDuration(props.id)
      panelStore.setPanelLoading(false)

    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      loadTaskDuration(cb)
      panelStore.setPanelLoading(false)
    })
    return {
      loadData,
      taskReq,
      data,
      taskStore,
      state,
      panelStore,
      currentTimer,
      timerStore,
      buttonSubmitting,
      completeValidate,
      taskDuration,
      ...getCurrentSpaceAccess(),
      noteState,
      ...LoadPanel(),
      homePageStore,
    }
  },
  methods: {
    onAddNote() {
      const modal = this.$refs.formAddNote as typeof BaseModal;
      modal.hideBaseModal();
      const note = this.$refs.notesRef as typeof Notes;
      note.paginationDataLoad();
    },
    addNote() {
      this.noteState.description = ''
      this.noteState.taskIdRequest = this.id;
      const modal = this.$refs.formAddNote as typeof BaseModal;
      modal.showBaseModal();
    },
    claim(task) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      TaskService.pick(task.id).then(res => {
        this.data = res;
        this.taskStore.setTask(res);
      }).finally(() => {
        Swal.close();
      })
    },
    start(task) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      TaskService.start(task.id).then(res => {
        this.data = res;
        this.taskStore.setTask(res);
      }).finally(() => {
        Swal.close();
      })
    },

    cancel() {
      const modal = this.$refs.completeTaskRef as typeof BaseModal
      modal.hideBaseModal();
    },
    onSaveComplete() {
      this.state.submitting = true
      TaskService.complete(this.data.id, {
        results: this.state.results,
        comments: this.state.comments,
        completeStatus: this.state.completeStatus,
        duration: this.state.duration,
      }).then(res => {
        this.data = res
        this.taskStore.setTask(res);
        const modal = this.$refs.completeTaskRef as typeof BaseModal
        modal.hideBaseModal();
        const note = this.$refs.notesRef as typeof Notes;
        note.paginationDataLoad();
        this.homePageStore.removeTask(res.id)
      }).finally(() => {
        this.state.submitting = false;
      })
    },
    onStartTimer() {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      const model = {objectId: this.data.id, objectType: 'TASK', name: this.data.name}
      TimerService.create(model).then(res => {
        this.timerStore.setCurrenTimer(res);
      }).finally(() => {
        Swal.close();
      })
    },

    onStop(id) {
      this.buttonSubmitting = true
      TimerService.complete(id).then(res => {
        this.timerStore.setCurrenTimer(res)
      }).finally(() => {
        this.buttonSubmitting = false;
      })
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TaskService.delete(id).then(() => {
            this.panelStore.hidePanel();
            this.taskStore.remove(id)
            this.homePageStore.removeTask(id)
          })
        }
      })
    },
    onEdit(task) {
      this.taskReq = {
        name: task.name,
        inputs: task.inputs,
        description: task.description,
        dueDate: task.dueDate,
        assign: task.team ? 'TEAM' : 'USER',
        ownerIdRequest: task.owner ? task.owner.id : '',
        teamIdRequest: task.team?.id ? task.team.id : '',
        approvalStatus: task.approvalStatus,
        approvalIdRequest: task.approvalBy ? task.approvalBy.id : ''
      }
      const modal = this.$refs.editTaskModalRef as typeof BaseModal
      modal.showBaseModal();
    },

    onCompleteTask() {
      console.log(this.data.duration);
      this.state.duration = this.data.duration === 0 ? 5 : this.data.duration;
      this.state.completeStatus = 'DONE'
      this.state.results = this.data.results
      this.state.comments = this.data.comments
      const modal = this.$refs.completeTaskRef as typeof BaseModal
      modal.showBaseModal();
    },

    async onEdited() {
      this.data = await this.loadData(this.data.id)
      const modal = this.$refs.editTaskModalRef as typeof BaseModal
      modal.hideBaseModal();
    },

    onApprove() {
      const modal = this.$refs.formTaskApprove as typeof BaseModal;
      modal.showBaseModal();
    },

    onApproveResponse(res) {
      const modal = this.$refs.formTaskApprove as typeof BaseModal;
      modal.hideBaseModal();
      this.data = res
      const note = this.$refs.notesRef as typeof Notes;
      note.paginationDataLoad();
    }
  }
})
