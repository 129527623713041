
import {computed, defineComponent, ref} from "vue";
import {useTimerStore} from "@/store/timer.store";
import {PinaFilterObjects} from "@/core/composite/composite";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import DateTimePicker from "@/components/base/datetime/DateTimePicker.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseButton from "@/components/base/form/BaseButton.vue";
import TimerService from "@/core/services/TimerService";
import Duration from "@/views/timer/Duration.vue";
import Swal from "sweetalert2";
import {Field} from "vee-validate";

export default defineComponent({
  name: "TaskTimers",
  components: {Duration, BaseButton, QuickAction, DateTimePicker, DateTimeFormat, KtDatatable, Field},
  props: {
    objectType: {type: String, required: true},
    objectId: {type: String, required: true},
    name: {type: String, require: false},
  },
  setup(props) {
    const objectFilter = ref({objectId: props.objectId, objectType: props.objectType});
    const newModel = ref<any>({startTime: '', endTime: '', objectType: '', objectId: '', name: '', duration: 5});
    const adding = ref(false);
    const submitting = ref(false);
    const timerStore = useTimerStore();
    const page = computed(() => timerStore.page);
    const headers = [
      {
        name: 'Start Time',
        key: 'startTime',
        sortable: false
      },
      {
        name: 'End Time',
        key: 'endTime',
        sortable: false
      },
      {
        name: 'Duration',
        key: 'duration',
        sortable: false
      },
      {
        name: '',
        key: 'actions',
        sortable: false
      }
    ]
    return {
      timerStore,
      ...PinaFilterObjects(timerStore, objectFilter.value),
      objectFilter,
      page,
      headers,
      submitting,
      adding,
      newModel,
    }
  },
  watch: {
    objectId(cb) {
      this.objectFilter.objectId = cb;
      this.updateFilterObject(this.objectFilter);
    }
  },
  methods: {
    edit(row) {
      row.editing = true;
      this.newModel.startTime = row.startTime;
      this.newModel.endTime = row.endTime
    },
    onSave(row) {
      this.submitting = true
      if (row.id) {
        TimerService.update<any>(row.id, this.newModel).then(res => {
          row = res
          this.timerStore.updateTimer(res);
        }).finally(() => {
          this.submitting = false
        })
      } else {
        TimerService.addNew(this.newModel).then(() => {
          this.paginationDataLoad()
          this.adding = false;
        }).finally(() => {
          this.submitting = false
        })
      }
    },
    onCancel() {
      this.adding = false;
      this.timerStore.removeFirstTimer();
    },

    addNew() {
      this.adding = true;
      this.newModel = {
        startTime: new Date().getTime(),
        endTime: new Date().getTime(),
        objectId: this.objectId,
        objectType: this.objectType,
        duration: 5,
        name: this.name,
      }
      const timer = {
        startTime: new Date().getTime(),
        endTime: new Date().getTime(),
        objectId: this.objectId,
        objectType: this.objectType,
        duration: 5,
        editing: true
      }
      this.timerStore.addTimer(timer);
    },
    deleteTimer(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TimerService.delete(id).then(() => {
            this.paginationDataLoad()
          })
        }
      })
    }
  }
})
