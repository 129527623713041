
import {defineComponent, onMounted, ref, watch} from "vue";
import MetricValueService from "@/core/services/MetricValueService";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import ValueFormat from "@/views/metric/value/ValueFormat.vue";
import {usePanelStore} from "@/store/panel.store";

export default defineComponent({
  name: "MetricValuePanel",
  components: {ValueFormat, DateTimeFormat},
  props: {
    id: {}
  },
  setup(props) {
    const data = ref<any>({})
    const panelStore = usePanelStore();
    const loadData = async (id) => {
      return await MetricValueService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      panelStore.setPanelLoading(false)

    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      panelStore.setPanelLoading(false)
    })
    return {
      data,
      loadData,
    }
  }
})
