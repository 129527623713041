
import {defineComponent, ref} from "vue";
import Froala from "@/components/base/editor/Froala.vue";
import BaseButton from "@/components/base/form/BaseButton.vue";
import TaskService from "@/core/services/TaskService";

export default defineComponent({
  name: "TaskApproveForm",
  components: {BaseButton, Froala},
  props: {
    id: {},
  },
  emits: ['saved'],
  setup() {
    const submitting = ref(false);
    const model = ref({comments: '', approvalStatus: ''})
    const reset = () => {
      model.value = {comments: '', approvalStatus: ''}
    }
    return {
      submitting,
      reset,
      model
    }
  },
  methods: {
    onSave(status) {
      this.model.approvalStatus = status
      this.submitting = true
      TaskService.approve(this.id, this.model).then(res => {
        this.$emit('saved', res)
      }).finally(() => {
        this.submitting = false
      })
    }
  }
})
