
import {computed, defineComponent, onMounted, ref} from "vue";
import DocService from "@/core/services/DocService";
import {usePanelStore} from "@/store/panel.store";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import UserLink from "@/views/user/UserLink.vue";
import {GetSpaceId} from "@/core/composite/composite";

export default defineComponent({
  name: "PublishHistoryPanel",
  components: {UserLink, DateTimeFormat},
  props: {
    id: {}
  },
  setup(props) {
    const panelStore = usePanelStore()
    const data = ref<any>([])
    const loadData = async (id) => {
      return await DocService.histories(id);
    }
    const loading = computed(()=>panelStore.loading)

    onMounted(async () => {
      data.value = await loadData(props.id);
      panelStore.setPanelLoading(false)
    })

    return {
      ...GetSpaceId(),
      loading,
      data,
    }
  },
})
