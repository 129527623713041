export const MetricMenu = [
  {
    heading: "Know Your Numbers",
    pages: [
      {
        fontIcon: "bi-archive",
        heading: "Goals",
        route: "/goals"
      },

      {
        fontIcon: "bi-archive",
        heading: "Metrics",
        route: "/metric/all"
      },
    ],
  },
]

export const WorkMenu = [
  {
    heading: "Track Your Commitments",
    pages: [
      {
        fontIcon: "bi-archive",
        heading: "Calendar",
        route: "/work/calendar/weekly"
      },
      {
        fontIcon: "bi-archive",
        heading: "Work",
        route: "/work/all"
      },
    ],
  },
]

export const TaskMenu = [
  {
    heading: "Delegate Your Work",
    pages: [
      {
        fontIcon: "bi-archive",
        heading: "Tasks",
        route: "/task/all"
      },
    ],
  },
]

export const TimerMenu = [
  {
    heading: "Reports",
    pages: [
      {
        fontIcon: "bi-archive",
        heading: "Dashboard",
        route: "/track/overview"
      },
      {
        fontIcon: "bi-archive",
        heading: "Timers",
        route: "/track/timers"
      },
    ]
  },
]

export const DocLibMenu = [
  {
    heading: "Document Your Company",
    pages: [
      {
        fontIcon: "bi-archive",
        heading: "Libraries",
        route: "/libraries"
      },
    ],
  },
]

export const AdminMenu = [
  {
    heading: "Admin",
    pages: [
      {
        fontIcon: "bi-archive",
        heading: "Users",
        route: "/admin/users"
      },
    ]
  },
]