
import {defineComponent, ref} from "vue";
import MetricService from "@/core/services/MetricService";
import Row from "@/components/base/common/Row.vue";
import UserLink from "@/views/user/UserLink.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "MetricOverview",
  components: {EntityLoading, UserLink, Row},
  props: {
    id: {type: String, required: true}
  },
  setup(props) {
    const data = ref<any>({});
    const loading = ref(true);
    const loadData = (id) => {
      loading.value = true;
      MetricService.get(id).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false
      })
    }
    loadData(props.id)
    return {
      loadData,
      data, loading,
    }
  }
})
