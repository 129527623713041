
import {defineComponent, onMounted, ref, watch} from "vue";
import {usePanelStore} from "@/store/panel.store";
import TaskTemplateService from "@/core/services/TaskTemplateService"
import Row from "@/components/base/common/Row.vue";
import DocLink from "@/views/doc/DocLink.vue";

export default defineComponent({
  name: "TaskTemplatePanel",
  components: {DocLink, Row},
  props: {
    id: {type: String}
  },
  setup(props) {
    const panelStore = usePanelStore();
    const data = ref<any>({})

    const loadData = async (id) => {
      return await TaskTemplateService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      panelStore.setPanelLoading(false)

    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      panelStore.setPanelLoading(false)
    })
    return {
      data,
    }
  }
})
