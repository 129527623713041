import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _ctx.onSelect,
    class: _normalizeClass(_ctx.clazz),
    name: _ctx.name,
    inputFormat: "dd/MM/yyyy"
  }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "name"]))
}