
import {computed, defineComponent} from "vue";
import {useI18n} from "vue-i18n/index";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {Actions} from "@/store/enums/StoreEnums";
import JwtService, {getCurrentCompany} from "@/core/services/JwtService";
import CookieService from "@/core/services/CookieService";
import ApiService from "@/core/services/ApiService";
import {useAdminUserStore} from "@/store/admin/admin.user.store";
import GroupService from "@/core/services/GroupService";
import Avatar from "@/components/base/common/Avatar.vue";

export default defineComponent({
  name: "kt-user-menu",
  components: {Avatar},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "/media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "/media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "/edia/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "/media/flags/france.svg",
        name: "French",
      },
    };

    const signOut = async () => {
      await store
        .dispatch(Actions.LOGOUT)
      await router.push({name: "sign-in"})
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return countries[i18n.locale.value];
    });


    const adminUserStore = useAdminUserStore()
    adminUserStore.geImpersonal();
    const user = computed(() => store.getters.currentUser)
    const isImpersonal = computed(() => adminUserStore.impersonal)
    return {
      isImpersonal,
      adminUserStore,
      signOut,
      setLang,
      currentLanguage,
      currentLanguageLocale,
      countries,
      user
    };
  },
  methods: {
    getId() {
      return getCurrentCompany()
    },
    switchCompany(id) {
      GroupService.switchEntity(id, 'COMPANY').then(() => {
        window.location.href = "/home"
      })
    },
    switchBack() {
      const token = CookieService.get('SW_TOKEN_ID_OLD')
      JwtService.saveToken(token);
      ApiService.setHeader();
      this.adminUserStore.setImpersonal(false)
      this.$router.push("/admin/users");
    }
  }
});
