import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.user.avatar)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        alt: "Pic",
        src: _ctx.user.avatar,
        width: "40"
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("img", {
        key: 1,
        alt: "Pic",
        src: `https://ui-avatars.com/api/?name=`+_ctx.user.fullName + `&background=random`,
        width: "40"
      }, null, 8, _hoisted_2))
}