import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreakdownValue = _resolveComponent("BreakdownValue")!
  const _component_FileFiledValue = _resolveComponent("FileFiledValue")!
  const _component_RowCol = _resolveComponent("RowCol")!
  const _component_CurrencyFormat = _resolveComponent("CurrencyFormat")!

  return (_ctx.field.type ==='BREAKDOWN')
    ? (_openBlock(), _createBlock(_component_BreakdownValue, {
        key: 0,
        col: true,
        name: _ctx.field.name,
        id: _ctx.field.value
      }, null, 8, ["name", "id"]))
    : (_ctx.field.type ==='FILE')
      ? (_openBlock(), _createBlock(_component_RowCol, {
          key: 1,
          label: _ctx.field.name,
          type: `CUSTOM`,
          value: _ctx.field.value
        }, {
          custom: _withCtx(() => [
            _createVNode(_component_FileFiledValue, {
              "file-id": _ctx.field.value
            }, null, 8, ["file-id"])
          ]),
          _: 1
        }, 8, ["label", "value"]))
      : (_ctx.field.type ==='YES_NO')
        ? (_openBlock(), _createBlock(_component_RowCol, {
            key: 2,
            label: _ctx.field.name,
            value: _ctx.field.value === `1` ?'Yes' :_ctx.field.value === `0` ?'No' :'',
            "show-if-null": true
          }, null, 8, ["label", "value"]))
        : (_ctx.field.type ==='AMOUNT')
          ? (_openBlock(), _createBlock(_component_RowCol, {
              key: 3,
              label: _ctx.field.name,
              type: `CUSTOM`,
              value: _ctx.field.value
            }, {
              custom: _withCtx(() => [
                _createVNode(_component_CurrencyFormat, {
                  amount: Number(_ctx.field.value)
                }, null, 8, ["amount"])
              ]),
              _: 1
            }, 8, ["label", "value"]))
          : (_openBlock(), _createBlock(_component_RowCol, {
              key: 4,
              label: _ctx.field.name,
              value: _ctx.field.value,
              "show-if-null": true
            }, null, 8, ["label", "value"]))
}