
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "WorkStatus",
  props: {
    status: {type: String},
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const buildLabel = (code: any) => {
      switch (code) {
        case 'ACTIVE':
          data.value = {name: 'Active', clazz: 'primary'}
          break
        case 'DEACTIVATED':
          data.value = {name: 'InActive', clazz: 'warning'}
          break
        case 'ARCHIVED':
          data.value = {name: 'Archived', clazz: 'info'}
          break
      }
    }
    buildLabel(props.status);
    watch(() => props.status, (cb) => {
      buildLabel(cb);
    })
    return {
      data
    }
  }
})
