
import {defineComponent, ref} from "vue";
import BaseForm from "../../components/base/form/BaseForm.vue";
import Froala from "@/components/base/editor/Froala.vue";
import NoteService from "@/core/services/NoteService";

export default defineComponent({
  name: "FormNote",
  components: {Froala, BaseForm},
  props: {
    note: {}
  },
  emits: ['saved'],
  setup(props) {
    const model = ref(props.note);
    const submitting = ref(false);
    return {
      model,
      submitting,
    }
  },
  methods: {
    onSave() {
      this.submitting = true;
      NoteService.create(this.model).then(res => {
        this.$emit('saved', res)
      }).finally(() => {
        this.submitting = false
      })
    }
  }
})
