
import {defineComponent, onMounted, ref, watch} from "vue";
import {usePanelStore} from "@/store/panel.store";
import WorkService from "@/core/services/WorkService";
import WorkStatus from "@/views/work/WorkStatus.vue";
import Row from "@/components/base/common/Row.vue";
import WorkTasks from "@/views/work/WorkTasks.vue";
import DocLink from "@/views/doc/DocLink.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import WorkForm from "@/views/work/WorkForm.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "WorkPanel",
  components: {QuickAction, WorkForm, BaseModal, DocLink, WorkTasks, Row, WorkStatus},
  props: {
    id: {type: String}
  },
  setup(props) {
    const state = ref({
        name: 'Create Work',
        work: {
          name: '',
          assignedIdRequest: '',
          schedule: {period: 'DAILY', frequencies: []},
          description: '',
          docIdRequest: '',
        },
        workId: ''
      }
    )
    const panelStore = usePanelStore();
    const data = ref<any>({})
    const loadData = async (id) => {
      return await WorkService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      panelStore.setPanelLoading(false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      panelStore.setPanelLoading(false)
    })
    return {
      data,
      state,
      panelStore,
      loadData,
      ...LoadPanel(),
    }
  },
  methods: {
    onDeleteEntity(work) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await WorkService.delete(work.id).then(() => {
            window.location.reload();
          })
        }
      })
    },

    onEdit(entity) {
      this.state.work = {
        name: entity.name,
        assignedIdRequest: entity?.assigned?.id,
        schedule: {period: entity.schedule.period, frequencies: entity.schedule.frequencies},
        description: entity.description,
        docIdRequest: entity.docId
      }
      this.state.workId = entity.id;
      this.state.name = 'Edit Work'
      const modal = this.$refs.workFormPanelRef as typeof BaseModal;
      modal.showBaseModal();
    },
    async onSaved() {
      const modal = this.$refs.workFormPanelRef as typeof BaseModal
      modal.hideBaseModal();
      this.data = await this.loadData(this.data.id);
    },
    onUpdateStatus(id, status) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      WorkService.updateStatus(id, status).then(async () => {
        this.data = await this.loadData(this.data.id);
      }).finally(() => {
        Swal.close();
      })
    },
  }

})
