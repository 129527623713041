import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e1fb188"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tk-panel",
  style: {"position":"fixed","z-index":"9999"}
}
const _hoisted_2 = {
  href: "javascript:void(0)",
  class: "pull-right tk-close"
}
const _hoisted_3 = { class: "tk-body" }
const _hoisted_4 = {
  key: 1,
  class: "offcanvas-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", _hoisted_2, [
            _createElementVNode("i", {
              class: "fa fa-times",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true)
  ], 64))
}