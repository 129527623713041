import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreakdownSelect = _resolveComponent("BreakdownSelect")!
  const _component_YesNoField = _resolveComponent("YesNoField")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_FileField = _resolveComponent("FileField")!
  const _component_AmountField = _resolveComponent("AmountField")!

  return (_ctx.type ==='BREAKDOWN')
    ? (_openBlock(), _createBlock(_component_BreakdownSelect, {
        key: 0,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        name: _ctx.name,
        "breakdown-id": _ctx.params?.breakdownId
      }, null, 8, ["modelValue", "name", "breakdown-id"]))
    : (_ctx.type ==='YES_NO')
      ? (_openBlock(), _createBlock(_component_YesNoField, {
          key: 1,
          name: _ctx.name,
          modelValue: _ctx.model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event))
        }, null, 8, ["name", "modelValue"]))
      : (_ctx.type ==='TEXTAREA')
        ? (_openBlock(), _createBlock(_component_TextAreaField, {
            key: 2,
            name: _ctx.name,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event))
          }, null, 8, ["name", "modelValue"]))
        : (_ctx.type==='TEXT')
          ? (_openBlock(), _createBlock(_component_TextField, {
              key: 3,
              name: _ctx.name,
              modelValue: _ctx.model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model) = $event))
            }, null, 8, ["name", "modelValue"]))
          : (_ctx.type ==='FILE')
            ? (_openBlock(), _createBlock(_component_FileField, {
                key: 4,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model) = $event))
              }, null, 8, ["modelValue"]))
            : (_openBlock(), _createBlock(_component_AmountField, {
                key: 5,
                name: _ctx.name,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model) = $event))
              }, null, 8, ["name", "modelValue"]))
}