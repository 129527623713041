import {defineStore} from "pinia";
import {useLoadingStore} from "@/store/loading";
import GroupService from "@/core/services/GroupService";

export const useGroupStore = defineStore('groupStore', {
  state: () => ({
    page: {
      total: 0,
      data: [],
      totalPages: 0,
    },
    entity: {},
    pageSecond: <any>{
      total: 0,
      data: [],
      totalPages: 0,
    },
    dashboards: <any[]>[],
  }),
  actions: {
    getDashboards() {
      GroupService.getDashboards().then(res => {
        this.dashboards = res;
      })
    },
    list(filter) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true);
      GroupService.findAll<any>(filter).then(res => {
        this.page = res
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    },
    listSecond(filter) {
      GroupService.findAll<any>(filter).then(res => {
        this.pageSecond = res
      })
    },
    get(id) {
      console.log("get grouo id ",id);
      GroupService.get<any>(id).then(res => {
        this.entity = res;
      })
    },
    setEntity(entity) {
      this.entity = entity
    }
  }
})