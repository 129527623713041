import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Timer = _resolveComponent("Timer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.clazz),
    style: _normalizeStyle(_ctx.stopwatch.days.value  > 0 ? 'width:150px': 'width:130px')
  }, [
    _createVNode(_component_Timer, {
      days: _ctx.stopwatch.days,
      seconds: _ctx.stopwatch.seconds,
      minutes: _ctx.stopwatch.minutes,
      hours: _ctx.stopwatch.hours
    }, null, 8, ["days", "seconds", "minutes", "hours"])
  ], 6))
}