import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (section) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "fs-5 text-muted mb-4",
      key: section.id
    }, [
      _createElementVNode("span", {
        class: "text-primary pe-2 cursor-pointer hover fw-bold",
        onClick: ($event: any) => (_ctx.loadPanel({title: section.name, helpId: section.id},'HELP'))
      }, _toDisplayString(section.name), 9, _hoisted_1)
    ]))
  }), 128))
}