
import {defineComponent, ref} from "vue";
import {Field} from "vee-validate";

export default defineComponent({
  name: "TextAreaField",
  components: {Field},
  props: {
    modelValue: {},
    name: {},
    clazz: {default: 'form-control-solid form-control fw-bold'}
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const model = ref(props.modelValue);
    const onChange = (event) => {
      emit('update:modelValue', event.target.value);
    }
    return {
      model,
      onChange,
    }

  }
})
