
import Datepicker from 'vue3-datepicker'
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "DateTimeSelect",
  components: {Datepicker},
  props: {
    name: {required: false, default: ''},
    modelValue: {
      required: true,
      default: new Date().getTime()
    },
    clazz: {required: false, default: 'form-control form-control-sm'}
  },
  setup(props) {
    const selected = ref(new Date(props.modelValue));
    watch(() => props.modelValue, cb => {
      selected.value = new Date(cb)
    })
    return {
      selected
    }
  },
  emit: ['update:modelValue'],
  methods: {
    onSelect(e) {
      this.selected = e;
      this.$emit('update:modelValue', e.getTime())
    }
  }
})
