import {defineStore} from "pinia";
import HomeService from "@/core/services/HomeService";

export const useHomePageStore = defineStore('', {
  state: () => ({
    today: <any>{
      tasks: [],
      metrics: [],
      works: [],
    }
  }),
  actions: {
    getTodayWork() {
      HomeService.getTodayWork().then(res => {
        this.today = res;
      })
    },
    removeMetric(metricId) {
      const metrics = this.today.metrics;
      this.today.metrics = metrics.filter(item => item.id !== metricId);
    },
    removeTask(taskId) {
      const tasks = this.today.tasks;
      this.today.tasks = tasks.filter(item => item.id !== taskId);
      const works = this.today.works
      this.today.works = works.filter(item => item.id !== taskId)
    }
  }
})