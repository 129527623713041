import {defineStore} from "pinia";
import ActionLogService from "@/core/services/ActionLogService";
import MetricValueService from "@/core/services/MetricValueService";
import {useLoadingStore} from "@/store/loading";

export const useActionLogStore = defineStore('actionLogStore', {
  state: () => ({
    page: <any>{
      loading: true,
      logs: <any>{
        total: 0,
        data: [],
        totalPages: 0,
      }
    },
    pageSecond: {
      total: 0,
      data: [],
      totalPages: 0,
    }
  }),
  actions: {
    listSecond(filter) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true)
      MetricValueService.findAll<any>(filter).then(res => {
        this.pageSecond = res
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    },

    list(filter) {
      this.page.loading = true
      ActionLogService.findAll(filter).then(res => {
        this.page.logs = res;
      }).finally(() => {
        this.page.loading = false;
      })
    }
  }
})