
import {defineComponent, ref, watch} from "vue";
import CodeService from "@/core/services/CodeService";
import RowCol from "@/components/base/common/RowCol.vue";

export default defineComponent({
  name: "BreakdownValue",
  components: {RowCol},
  props: {
    id: {},
    name: {},
    col: {default: true}
  },
  setup(props) {
    const breakdown = ref<any>({});
    const load = (id) => {
      if (id) {
        CodeService.get(id).then(res => {
          breakdown.value = res;
        })
      } else {
        breakdown.value = {};
      }
    }
    load(props.id);
    watch(() => props.id, cb => {
      load(cb);
    })
    return {
      breakdown,
    }
  }
})
