import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"200px"} }
const _hoisted_2 = {
  key: 0,
  class: "separator"
}
const _hoisted_3 = {
  key: 1,
  class: "justify-content-end"
}
const _hoisted_4 = {
  key: 0,
  href: "javascript:void(0);",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_5 = {
  key: 1,
  class: "fa fa-question-circle cursor-pointer",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, {
    arrow: "",
    hover: ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.desc) + " ", 1),
        (_ctx.helpId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.helpId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("a", {
                class: "text-primary cursor-pointer hover",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadPanel({helpId: _ctx.helpId}, 'HELP')))
              }, _toDisplayString(_ctx.helpTitle), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("a", _hoisted_4, _toDisplayString(_ctx.title), 1))
        : (_openBlock(), _createElementBlock("i", _hoisted_5))
    ]),
    _: 1
  }))
}