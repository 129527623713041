
import {defineComponent, onMounted, ref, watch} from "vue";
import {usePanelStore} from "@/store/panel.store";
import CodeService from "@/core/services/CodeService";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import CodeForm from "@/views/code/CodeForm.vue";
import Swal from "sweetalert2";

export default defineComponent({
  name: "CodePanel",
  components: {CodeForm, BaseModal, QuickAction},
  props: {
    id: {}
  },
  setup(props) {
    const state = ref<any>({name: 'Add Code', id: '', code: {name: '', parentIdRequest: ''}})
    const data = ref<any>({})
    const options = ref<any[]>([]);
    const loadOptions = () => {
      CodeService.getOptions(props.id).then(res => {
        options.value = res
      })
    }
    const panelStore = usePanelStore();
    const loadData = async (id) => {
      return await CodeService.get(id);

    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      loadOptions()
      panelStore.setPanelLoading(false)
    })

    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      loadOptions()
      panelStore.setPanelLoading(false)
    })
    return {
      state,
      options,
      data,
      loadOptions,
    }
  },
  methods: {
    onSave() {
      this.loadOptions()
      const modal = this.$refs.formCodePanelRef as typeof BaseModal;
      modal.hideBaseModal();
    },
    onEdit(entity) {
      this.state.name = 'Edit Option'
      this.state.id = entity.id
      this.state.code = {name: entity.name, parentIdRequest: this.id}
      const modal = this.$refs.formCodePanelRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await CodeService.delete(id).then(() => {
            this.loadOptions()
          })
        }
      })
    },
    onAdd() {
      this.state.name = 'Add Option';
      this.state.id = ''
      this.state.code = {name: '', parentIdRequest: this.id}
      const modal = this.$refs.formCodePanelRef as typeof BaseModal;
      modal.showBaseModal();
    },
  }
})
