
import {defineComponent, ref, watch} from "vue";
import CodeService from "@/core/services/CodeService";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "BreakdownSelect",
  components: {BaseSelect},
  props: {
    modelValue: {},
    name: {},
    breakdownId: {},
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const model = ref(props.modelValue);
    watch(() => props.modelValue, cb => {
      model.value = cb;
    })

    const options = ref([]);
    const loadOptions = (id) => {
      CodeService.getOptions(id).then(res => {
        options.value = res;
      })
    }
    loadOptions(props.breakdownId)
    watch(() => props.breakdownId, c => {
      loadOptions(c)
    })
    const onChange = (value) => {
      emit('update:modelValue', value)
    }
    return {
      onChange,
      model,
      options,
    }
  }
})
