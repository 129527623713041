
import {defineComponent, ref} from "vue";
import {LoadPanel} from "@/core/composite/composite";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import DocService from "@/core/services/DocService";
import Row from "@/components/base/common/Row.vue";
import PageStatus from "@/views/doc/PageStatus.vue";

export default defineComponent({
  name: "DocOverview",
  components: {PageStatus, Row, EntityLoading},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({});
    const loading = ref(false);
    const loadDoc = (id) => {
      loading.value = true;
      DocService.get(id).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false
      })
    }
    loadDoc(props.id)
    return {
      ...LoadPanel(),
      data,
      loading,
    }
  }
})
