import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    name: _ctx.name,
    type: "number",
    class: _normalizeClass(_ctx.clazz),
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    onChange: _ctx.onChange
  }, null, 8, ["name", "class", "modelValue", "onChange"]))
}