import {defineStore} from "pinia";
import AuditLogService from "@/core/services/AuditLogService";

export const useAuditStore = defineStore('auditLogStore', {
  state: () => ({
    page: <any>{
      total: 0,
      data: [],
      totalPages: 0
    }
  }),
  actions: {
    list(filter) {
      AuditLogService.findAll(filter).then(res => {
        this.page = res;
      })
    }
  }
})