import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_select = _resolveComponent("base-select")!

  return (_openBlock(), _createBlock(_component_base_select, {
    data: _ctx.options,
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    name: _ctx.name,
    onOnSelected: _ctx.onChange
  }, {
    default: _withCtx(({option: option}) => [
      _createElementVNode("option", {
        value: option.id
      }, _toDisplayString(option.name), 9, _hoisted_1)
    ]),
    _: 1
  }, 8, ["data", "modelValue", "name", "onOnSelected"]))
}