
import {defineComponent, ref} from "vue";
import MetricValueService from "@/core/services/MetricValueService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import Row from "@/components/base/common/Row.vue";

export default defineComponent({
  name: "MetricValueOverview",
  components: {Row, EntityLoading},
  props: {
    id: {type: String, required: true}
  },
  setup(props) {
    const data = ref<any>({});
    const loading = ref(true);
    const loadData = (id) => {
      if (id) {
        loading.value = true
        MetricValueService.get(id).then(res => {
          data.value = res;
        }).finally(() => {
          loading.value = false
        })
      } else {
        data.value = {};
      }
    }
    loadData(props.id)
    return {
      loadData,
      loading,
      data,
    }
  }
})
