import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-6" }
const _hoisted_2 = { class: "col fv-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Froala = _resolveComponent("Froala")!
  const _component_base_form = _resolveComponent("base-form")!

  return (_openBlock(), _createBlock(_component_base_form, {
    submitting: _ctx.submitting,
    onFormSubmit: _ctx.onSave
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Froala, {
            modelValue: _ctx.model.description,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.description) = $event))
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["submitting", "onFormSubmit"]))
}