
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "TrainingUserStatus",
  props: {
    status: {type: String},
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const buildLabel = (code: any) => {
      switch (code) {
        case 'NOT_STARTED':
          data.value = {name: 'Not Started', clazz: 'secondary'}
          break
        case 'COMPLETED':
          data.value = {name: 'Completed', clazz: 'info'}
          break
        case 'IN_PROGRESS':
          data.value = {name: 'In-progress', clazz: 'primary'}
          break
        default:
          data.value = {name: '', clazz: 'primary'}
      }
    }

    buildLabel(props.status);
    watch(() => props.status, c => {
      buildLabel(c);
    });
    return {
      data,
    }
  }
})
