
import {computed, defineComponent} from "vue";
import store from "@/store";
import {getCurrentCompany, getWorkspaceId} from "@/core/services/JwtService";
import GroupService from "@/core/services/GroupService";


export default defineComponent({
  name: "SelectWorkspace",
  setup() {
    const workspaces = computed(() => store.getters.currentUser.workspaces);
    const user = computed(()=>store.getters.currentUser);
    return {
      user,
      workspaces,
      getWorkspaceId,
      getCurrentCompany
    }
  },
  methods: {
    checkWorkspace(id) {
      return id === getCurrentCompany();
    },
    onSelected(id) {
      console.log(id)
      return getWorkspaceId() === id;
    },
    selectedWorkspace(id) {
      GroupService.switchEntity(id, 'SPACE').then(() => {
        window.location.reload();
      })
    }
  }
})
