
import {computed, defineComponent, reactive, ref} from "vue";
import {useI18n} from "vue-i18n/index";
import KTMenu from "@/layout/aside/Menu.vue";
import {asideTheme} from "@/core/helpers/config";
import {usePanelStore} from "@/store/panel.store";
import store from "@/store";
import Panel from "@/components/base/panel/Panel.vue";
import ShortPageForm from "@/views/doc/ShortPageForm.vue";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import TaskCreateForm from "@/views/task/TaskCreateForm.vue";
import {getCurrentSpaceAccess, getCurrentSpaceName, GetSpaceId} from "@/core/composite/composite";
import GroupService from "@/core/services/GroupService";

export default defineComponent({
  name: "KTAside",
  components: {
    TaskCreateForm,
    BaseModal,
    ShortPageForm,
    Panel,
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const state = reactive({
      showPanel: false,
      title: '',
      model: {name: '', type: '', body: '', parentId: '', description: '', config: {allowFile: true, allowFaq: true}}
    });
    const task = ref({
      assign: 'MY_SELF',
      dueDate: new Date().getTime(),
      ownerIdRequest: '',
      docIdRequest: '',
      approvalIdRequest: ''
    });
    const submitting = ref(false);

    const {t} = useI18n();
    const panelStore = usePanelStore();
    const workspaces = computed(() => store.getters.currentUser.workspaces);
    const user = computed(() => store.getters.currentUser);
    return {
      user,
      panelStore,
      workspaces,
      asideTheme,
      t,
      task,
      state,
      submitting,
      ...getCurrentSpaceAccess(),
      ...GetSpaceId(),
      ...getCurrentSpaceName(),
    };
  },
  methods: {
    onCancelTaskForm() {
      const modal = this.$refs.quickAddTaskRef as typeof BaseModal
      modal.hideBaseModal();
    },
    onResetModel(type, parentId) {
      this.state.model.name = ''
      this.state.model.type = type
      this.state.model.parentId = parentId
      this.state.model.body = ''
      this.state.model.description = ''
      this.state.model.config = {allowFile: true, allowFaq: true}
    },

    currentWorkspace(workspaces, spaceId) {
      return workspaces?.find(item => item.id === spaceId);
    },

    onSelectWorkspace() {
      this.panelStore.setSelectWorkspace(true)
    },
    onClosePanel() {
      this.state.showPanel = false
    },
    onSavePage(data) {
      store.dispatch(Actions.ADD_PAGE, data)
      this.onClosePanel();
      store.commit(Mutations.SET_PAGE_TO_LIST, data);
    },
    addCollection() {
      this.onResetModel('COLLECTION', '')
      this.state.title = 'New Collection'
      this.state.showPanel = true
    },
    addTask() {
      this.task = {
        assign: 'MY_SELF',
        dueDate: new Date().getTime(),
        ownerIdRequest: '',
        docIdRequest: '',
        approvalIdRequest: this.user.id
      }
      const modal = this.$refs.quickAddTaskRef as typeof BaseModal
      modal.showBaseModal();
    },
    onSave() {
      const modal = this.$refs.quickAddTaskRef as typeof BaseModal
      modal.hideBaseModal();
    },
    selectedWorkspace(id) {
      GroupService.switchEntity(id, 'SPACE').then(() => {
        window.location.href = "/" + this.user.currentCompanyId + "/home";
      })
    }
  }
});
