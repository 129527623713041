
import {defineComponent, ref, watch} from "vue";
import DocUserService from "@/core/services/DocUserService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import Row from "@/components/base/common/Row.vue";
import UserLink from "@/views/user/UserLink.vue";
import TrainingUserStatus from "@/views/training/TrainingUserStatus.vue";

export default defineComponent({
  name: "TrainingOverview",
  components: {TrainingUserStatus, UserLink, Row, EntityLoading},
  props: {
    id: {}
  },
  setup(props) {
    const loading = ref(false);
    const data = ref<any>({});
    const loadData = (id) => {
      loading.value = true;
      DocUserService.get(id).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    loadData(props.id)
    watch(() => props.id, cb => {
      loadData(cb)
    })
    return {
      data,
      loading,
    }
  }
})
