
import {defineComponent} from 'vue'
import Digit from "@/components/base/timer/Digit.vue";

export default defineComponent({
  name: 'Timer',
  components: {Digit},
  props: {
    days: {
      type: Number,
      required: true
    },
    hours: {
      type:Number,
      required: true
    },
    minutes: {
      type: Number,
      required: true
    },
    seconds: {
      type: Number,
      required: true
    },
  },

})

