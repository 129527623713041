
import {defineComponent, ref} from "vue";
import {Field} from "vee-validate";
import BaseForm from "@/components/base/form/BaseForm.vue";
import PageService from "@/core/services/DocService";
import store from "@/store";
import FullFroala from "@/components/base/editor/FullFroala.vue";

export default defineComponent({
  name: "ShortPageForm",
  components: {FullFroala, BaseForm, Field},
  props: {
    page: {type: Object, required: true},
    id: {type: String}
  },
  emits: ['closePanel'],
  setup(props, {emit}) {
    const submitting = ref(false)
    const model = ref(props.page);
    const onClosePanel = () => {
      emit('closePanel')
    }
    return {
      submitting,
      model,
      onClosePanel,
      store,
    }
  },
  methods: {
    onSavePage() {
      this.submitting = true;
      if (this.id) {
        PageService.update<any>(this.id, this.model).then(res => {
          this.$emit('closePanel', res)
        }).finally(() => {
          this.submitting = false;
        })
      } else {
        PageService.create<any>(this.model).then(res => {
          this.$emit('closePanel', res)
        }).finally(() => {
          this.submitting = false;
        })
      }
    }
  }
})
