
import {defineComponent, ref} from "vue";
import TaskService from "@/core/services/TaskService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import Row from "@/components/base/common/Row.vue";
import ApprovalStatus from "@/views/task/ApprovalStatus.vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "TaskOverview",
  components: {ApprovalStatus, Row, EntityLoading},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({});
    const loading = ref(false);
    const loadTask = (id) => {
      loading.value = true;
      TaskService.get(id).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false
      })
    }
    loadTask(props.id)
    return {
      ...LoadPanel(),
      data,
      loading,
    }
  }
})
