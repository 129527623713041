import {defineStore} from "pinia";
import TimerService from "@/core/services/TimerService";


export const useTimerStore = defineStore('timerStore', {
  state: () => ({
    currentTimer: {},
    page: {
      total: 0,
      data: [],
      totalPages: 0,
    }
  }),
  actions: {
    list(filter: any) {
      TimerService.findAll<any>(filter).then(res => {
        const data = res.data.map(item => ({...item, editing: false}));
        this.page = {total: res.total, data: data, totalPages: res.totalPages};
      })
    },
    setCurrenTimer(timer) {
      this.currentTimer = timer;
    },
    getCurrent() {
      TimerService.getCurrent().then(res => {
        this.currentTimer = res;
      })
    },
    updateTimer(timer: any) {
      const indexToUpdate = this.page.data.findIndex(item => item['id'] === timer.id);
      // @ts-ignore
      this.page.data[indexToUpdate] = timer;
    },

    addTimer(timer) {
      // @ts-ignore
      this.page.data.unshift(timer);
    },
    removeFirstTimer() {
      this.page.data = this.page.data.slice(1);
    }
  }
});