
import {defineComponent, onMounted} from "vue";
import EntityActionLogs from "@/views/entity/EntityActionLogs.vue";
import {usePanelStore} from "@/store/panel.store";
import AuditLogs from "@/views/entity/AuditLogs.vue";
import TaskOverview from "@/views/task/TaskOverview.vue";
import MetricOverview from "@/views/metric/MetricOverview.vue";
import MetricValueOverview from "@/views/metric/value/MetricValueOverview.vue";
import {CurrentUser} from "@/core/composite/composite";
import Row from "@/components/base/common/Row.vue";
import DecodeId from "@/components/common/DecodeId.vue";
import DocOverview from "@/views/doc/DocOverview.vue";
import WorkOverview from "@/views/work/WorkOverview.vue";
import TrainingOverview from "@/views/training/TrainingOverview.vue";

export default defineComponent({
  name: "EntityPanel",
  components: {
    TrainingOverview,
    WorkOverview,
    DocOverview,
    DecodeId, Row, MetricValueOverview, MetricOverview, TaskOverview, AuditLogs, EntityActionLogs
  },
  props: {
    id: {required: true}
  },
  setup() {
    const panelStore = usePanelStore();
    onMounted(async () => {
      panelStore.setPanelLoading(false)
    })
    return {
      ...CurrentUser(),
    }
  }
})
