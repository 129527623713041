import {defineStore} from "pinia";
import DocService from "@/core/services/DocService";

export const useHelpStore = defineStore('helpStore', {
  state: () => ({
    defaultId : process.env.VUE_APP_HELP_ID, // default Help ID
    helpId: process.env.VUE_APP_HELP_ID,
    rootHelp: {}
  }),
  actions: {
    setHelpId(id) {
      this.helpId = id;
    },
    async getHelp() {
      await DocService.getLibraryHelp().then(res => {
        this.rootHelp = res;
      })
    }
  }
})