
import {defineComponent, onMounted, ref, watch} from "vue";
import {usePanelStore} from "@/store/panel.store";
import DocumentService from "@/core/services/DocumentService";

export default defineComponent({
  name: "FilePreview",
  props: {
    id: {},
  },
  setup(props) {
    const data = ref<any>({type: 'LOADING'});
    const loadData = async (id) => {
      return DocumentService.getUrl(id)
    }
    const panelStore = usePanelStore();
    onMounted(async () => {
      data.value = await loadData(props.id)
      panelStore.setPanelLoading(false)

    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      panelStore.setPanelLoading(false)
    })
    return {
      data
    }
  }
})
