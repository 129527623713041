
import {defineComponent, onMounted, ref, watch} from "vue";
import UserService from "@/core/services/UserService";
import {usePanelStore} from "@/store/panel.store";

export default defineComponent({
  name: "UserPanel",
  props: {id: {type: String, required: true}},
  setup(props) {
    const panelStore = usePanelStore();
    const user = ref<any>({});
    const loadUser = async (id) => {
      return await UserService.get(id);
    }
    onMounted(async () => {
      user.value = await loadUser(props.id)
      panelStore.setPanelLoading(false)

    })
    watch(() => props.id, async (cb) => {
      user.value = await loadUser(cb)
      panelStore.setPanelLoading(false)
    })
    return {
      user,
    }
  }
})
