
import {computed, defineComponent, ref} from "vue";
import {PinaFilterObjects} from "@/core/composite/composite";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import NoteService from "@/core/services/NoteService";
import {useNoteStore} from "@/store/note.store";

export default defineComponent({
  name: "Notes",
  components: {QuickAction, DateTimeFormat, Avatar},
  props: {
    id: {type: String, required: true},
    type: {type: String, required: true},
    actions: {default: true}
  },
  setup(props) {

    const refId = ref(props.id);
    const refType = ref(props.type)
    const filterObject = ref({taskId: '',})
    switch (refType.value) {
      case 'TASK':
        filterObject.value.taskId = refId.value
        break
    }
    const noteStore = useNoteStore();
    const notePage = computed(() => noteStore.page)
    return {
      ...PinaFilterObjects(noteStore ,filterObject.value, ['createdBy']),
      notePage,
      filterObject
    }
  },
  watch: {
    id(objectId) {
      switch (this.type) {
        case 'TASK':
          this.filterObject.taskId = objectId;
          break
      }
      this.updateFilterObject(this.filterObject)
    }
  },
  methods: {
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await NoteService.delete(id).then(() => {
            this.paginationDataLoad()
          })
        }
      })
    }
  }
})
