
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {usePanelStore} from "@/store/panel.store";
import DocService from "@/core/services/DocService";
import HelpPanelRelated from "@/components/base/HelpPanelRelated.vue";
import {useHelpStore} from "@/store/help.store";

export default defineComponent({
  name: "HelpPanel",
  components: {HelpPanelRelated},
  props: {
    id: {type: Object, required: true},
  },
  setup(props) {
    const data = ref<any>({})
    const panelStore = usePanelStore();
    const helpStore = useHelpStore();
    const defaultHelpId = computed(() => helpStore.defaultId)
    const loadData = async (id) => {
      return await DocService.getHelp(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id.helpId)
      if (data.value.status !== 'SUCCESS') {
        data.value = await loadData(defaultHelpId.value);
      }
      panelStore.setPanelLoading(false)
    })

    watch(() => props.id, async cb => {
      data.value = await loadData(cb.helpId)
      if (data.value.status !== 'SUCCESS') {
        data.value = await loadData(defaultHelpId.value);
      }
      panelStore.setPanelLoading(false)
    })
    return {
      data,
    }
  }
})
