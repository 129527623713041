
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "DateTimePicker",
  components: {flatPickr},
  props: {
    modelValue: {
      required: true,
      default: new Date().getTime()
    },
    clazz: {required: false, default: 'form-control form-control-sm'}
  },
  setup(props) {
    const selected = ref(new Date(props.modelValue));
    const config = {
      enableTime: true,
    }
    return {
      config,
      selected
    }
  },
  emit: ['update:modelValue'],
  methods: {
    onSelect(e) {
      this.$emit('update:modelValue', e[0].getTime())
    }
  }
})
