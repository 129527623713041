
import {computed, defineComponent, ref} from "vue";
import {PinaFilterObjects} from "@/core/composite/composite";
import {useActionLogStore} from "@/store/actionlog.store";
import ActionLogCards from "@/views/entity/ActionLogCards.vue";

export default defineComponent({
  name: "EntityActionLogs",
  components: {ActionLogCards},
  props: {
    entity: {type: Object, required: true}
  },
  setup(props) {
    const filterObject = ref({objectId: props.entity.objectId, objectType: props.entity.objectType, subType: props.entity.subType})
    const actionLogStore = useActionLogStore();
    const page = computed(() => actionLogStore.page);
    return {
      page,
      actionLogStore,
      filterObject,
      ...PinaFilterObjects(actionLogStore, filterObject.value, ['user'])
    }
  }
})
