
import {computed, defineComponent, ref} from "vue";
import {PinaFilterObjects} from "@/core/composite/composite";
import {useAuditStore} from "@/store/audit.store";
import Pagination from "@/components/base/paging/Pagination.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import UserLink from "@/views/user/UserLink.vue";
import AuditAction from "@/views/entity/AuditAction.vue";

export default defineComponent({
  name: "AuditLogs",
  components: {AuditAction, UserLink, DateTimeFormat, Pagination},
  props: {
    entity: {required: true, type:Object},
  },
  setup(props) {
    const filterObject = ref({objectType: props.entity.objectType, objectId: props.entity.objectId})
    const auditStore = useAuditStore();
    const page = computed(() => auditStore.page);
    return {
      page,
      filterObject,
      auditStore,
      ...PinaFilterObjects(auditStore, filterObject.value, ['user'])
    }
  }
})
