
import {defineComponent} from "vue";
import {LoadPanel} from "@/core/composite/composite";
import Popper from "vue3-popper";
export default defineComponent({
  name: "HelpTitle",
  components:{Popper},
  props: {
    title: {type: String, required: false},
    helpId: {type: String, required: false},
    desc: {type:String, required: true},
    helpTitle: {default: 'Lean more'},
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
