
import {computed, defineComponent} from "vue";
import {PinaFilterObjects} from "@/core/composite/composite";
import {useMessageStore} from "@/store/message.store";
import MessageLink from "@/views/message/MessageLink.vue";

export default defineComponent({
  name: "Messages",
  components: {MessageLink},
  setup() {
    const messageStore = useMessageStore();
    const page = computed(() => messageStore.page);
    return {
      page,
      ...PinaFilterObjects(messageStore, {}, ['user'])
    }
  }
})
