
import {defineComponent, ref, watch} from "vue";
import {Field} from "vee-validate";

export default defineComponent({
  name: "AmountField",
  components:{Field},
  props: {
    modelValue: {required: true},
    name: {},
    clazz: {default: 'form-control-solid form-control fw-bold'}
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const model = ref(props.modelValue);
    watch(()=>props.modelValue, cb=>{
      model.value = cb;
    })
    const onChange = (event) => {
      emit('update:modelValue', event.target.value);
    }
    return {
      onChange,
      model,
    }
  }
})
