import {defineStore} from "pinia";
import {useLoadingStore} from "@/store/loading";
import AdminUserService from "@/core/services/admin/AdminUserService";
import CookieService from "@/core/services/CookieService";
import {COOKIE_KEY} from "@/core/config/Constant";


export const useAdminUserStore = defineStore('adminUserStore', {
  state: () => ({
    page: {
      total: 0,
      data: [],
      totalPages: 0
    },
    entity: {},
    impersonal: <boolean>false,
  }),


  actions: {
    list(filter) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true)
      AdminUserService.findAll<any>(filter).then(res => {
        this.page = res;
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    },
    get(id) {
      AdminUserService.get<any>(id).then(res => {
        this.entity = res
      })
    },
    setImpersonal(value: boolean) {
      CookieService.save(COOKIE_KEY.IMPERSONAL, value);
    },
    geImpersonal() {
      const im = CookieService.get(COOKIE_KEY.IMPERSONAL);
      console.log(im);
      this.impersonal = im === 'true';
    }
  }
})