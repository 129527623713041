import { BaseService } from "@/core/services/BaseService";
import { ENDPOINT } from "@/core/config/Constant";
import { buildFormData } from "@/core/helpers/functions";

class DocumentService extends BaseService<any> {
  endpoint = ENDPOINT.DOCUMENT

  async upload(data) {
    const formData = buildFormData(data)
    return await this.uploadHttp().post(this.endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      return res.data;
    })
  }

  async download(id) {
    return await this.http().get(this.endpoint + "/" + id + "/download",
      {responseType: "blob"}).then(res => {
      return res;
    })
  }
  async getUrl(id) {
    return await this.http().get(this.endpoint + "/" + id + "/presign-url").then(res => {
      return res.data
    })
  }

  async uploadBase64Image(data) {
    return await this.uploadHttp().post(this.endpoint + "/image/upload-image", data).then(res => {
      return res.data;
    })
  }

  async uploadImage(data) {
    const formData = buildFormData(data);
    return await this.uploadHttp().post(this.endpoint + "/image/upload", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then(res => {
      return res.data;
    })
  }
}

export default new DocumentService();