
import {defineComponent, ref, watch} from "vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";

export default defineComponent({
  name: "YesNoField",
  components: {BaseRadio},
  props: {
    modelValue: {},
    name: {},
    clazz: {default: 'form-control-solid form-control fw-bold'},
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const model = ref(props.modelValue);
    const onChange = (event) => {
      emit('update:modelValue', event.target.value);
    }
    watch(() => model.value, cb => {
      emit('update:modelValue', cb);
    })
    return {
      onChange,
      model,
    }
  }
})
