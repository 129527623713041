
import {computed, defineComponent, ref} from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";

import {asideDisplay, headerLeft, headerWidthFluid,} from "@/core/helpers/config";
import BaseButton from "@/components/base/form/BaseButton.vue";
import TimerService from "@/core/services/TimerService";
import {useTimerStore} from "@/store/timer.store";
import {LoadPanel} from "@/core/composite/composite";
import CurrentTimer from "@/views/timer/CurrentTimer.vue";
import {useHelpStore} from "@/store/help.store";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    CurrentTimer,
    BaseButton,
    KTTopbar,
  },
  setup() {
    const submitting = ref<boolean>(false)
    const store = useTimerStore();
    const currentTimer = computed<any>(() => store.currentTimer)


    const onStop = (id: any) => {
      submitting.value = true
      TimerService.complete(id).then(res => {
        store.setCurrenTimer(res)
      }).finally(() => {
        submitting.value = false;
      })
    }
    const helpStore = useHelpStore();
    const helpId = computed(() => helpStore.helpId);
    return {
      helpId,
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      currentTimer,
      onStop,
      ...LoadPanel(),
      submitting,
      store,
    };
  },
  methods: {

    onStart() {
      const model = {objectId: this.currentTimer.objectId, objectType: 'TASK', name: this.currentTimer.name}
      TimerService.create(model).then(res => {
        this.store.setCurrenTimer(res);
      })
    },

    showHelp() {
      if (this.helpId) {
        this.loadPanel({helpId: this.helpId}, 'HELP')
      }
    }

  }
});
