import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_Row = _resolveComponent("Row")!
  const _component_PageStatus = _resolveComponent("PageStatus")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_EntityLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_Row, {
          row: [{name: 'Created By', value: _ctx.data.createdBy, type:'USER'},  {name:'Created', type:'DATETIME', value: _ctx.data.dateCreated}]
        }, null, 8, ["row"]),
        _createVNode(_component_Row, {
          row: [{name:'Status', slot:true, key:'status'}, {name:'FAQ', value:_ctx.data.faq ? 'YES': 'NO'}]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PageStatus, {
              status: _ctx.data.status
            }, null, 8, ["status"])
          ]),
          _: 1
        }, 8, ["row"])
      ], 64))
}