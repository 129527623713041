
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "ApprovalStatus",
  props: {
    code: {type: String, required: true}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const buildLabel = (code: string) => {
      switch (code) {
        case 'REQUIRED':
          data.value = {name: 'Required', clazz: 'primary'}
          break
        case 'PENDING':
          data.value = {name: 'Pending', clazz: 'info'}
          break
        case 'REJECTED':
          data.value = {name: 'Rejected', clazz: 'danger'}
          break
        case 'ACCEPTED':
          data.value = {name: 'Accepted', clazz: 'success'}
          break
      }
    }
    buildLabel(props.code);
    watch(() => props.code, (cb) => {

      buildLabel(cb);
    })
    return {
      data
    }
  }
})
