
import {defineComponent, PropType, ref, watch} from "vue";
import YesNoField from "@/components/base/form/field/YesNoField.vue";
import TextAreaField from "@/components/base/form/field/TextAreaField.vue";
import TextField from "@/components/base/form/field/TextField.vue";
import AmountField from "@/components/base/form/field/AmountField.vue";
import BreakdownSelect from "@/components/base/form/field/BreakdownSelect.vue";
import FileField from "@/components/base/form/field/FileField.vue";

export default defineComponent({
  name: "BaseField",
  components: {FileField, BreakdownSelect, AmountField, TextField, TextAreaField, YesNoField},
  props: {
    type: {
      type: String as PropType<'NUMBER' | 'TEXT' | 'TEXTAREA' | 'AMOUNT' | 'YES_NO' | 'BREAKDOWN'>,
      default: 'NUMBER'
    },
    name: {},
    modelValue: {},
    params: {}
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const model = ref(props.modelValue);
    watch(() => props.modelValue, cb => {
      model.value = cb;
    })
    watch(() => model.value, cb => {
      console.log(cb);
      emit('update:modelValue', cb);
    })
    return {
      model,
    }
  }

})
