
import {defineComponent, ref, watch} from "vue";
import * as Yup from "yup";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import CodeService from "@/core/services/CodeService";
import {Field} from 'vee-validate'
export default defineComponent({
  name: "CodeForm",
  components: {FormErrorMessage, BaseForm,Field},
  props : {
    code : {},
    id :{type:String}
  },
  emits: ['save'],
  setup(props) {
    const submitting = ref(false);
    const validate = Yup.object().shape({
      name: Yup.string().required().label("Name"),
    });
    const model = ref(props.code);
    watch(() => props.code, (cb) => {
      model.value = cb;
    })

    return {
      validate,
      submitting,
      model,
    }
  },
  methods: {
    onSave() {
      this.submitting = true
      if (this.id) {
        CodeService.update(this.id, this.model).then(() => {
          this.$emit('save')
        }).finally(() => {
          this.submitting = false;
        })
      } else {
        CodeService.create(this.model).then(() => {
          this.$emit('save')
        }).finally(() => {
          this.submitting = false
        })
      }
    }
  }
})
