import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    name: _ctx.name,
    type: "text",
    class: _normalizeClass(_ctx.clazz)
  }, {
    default: _withCtx(({ field }) => [
      _createElementVNode("textarea", _mergeProps(field, {
        rows: "3",
        class: _ctx.clazz,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }), null, 16)
    ]),
    _: 1
  }, 8, ["name", "class"]))
}