
import {defineComponent, onMounted, ref, watch} from "vue";
import {usePanelStore} from "@/store/panel.store";
import MetricService from "@/core/services/MetricService";
import MetricValues from "@/views/metric/MetricValues.vue";
import {useMetricStore} from "@/store/metric.store";
import {GetSpaceId} from "@/core/composite/composite";
import MetricChart from "@/views/metric/MetricChart.vue";
import MetricName from "@/views/metric/MetricName.vue";

export default defineComponent({
  name: "MetricPanel",
  components: {MetricName, MetricChart, MetricValues},
  props: {
    id: {}
  },
  setup(props) {
    const data = ref<any>({})
    const panelStore = usePanelStore();
    const metricSore = useMetricStore();
    const loadData = async (id) => {
      return await MetricService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      metricSore.setEntity(data.value);
      panelStore.setPanelLoading(false)

    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      metricSore.setEntity(data.value);
      panelStore.setPanelLoading(false)
    })
    return {
      ...GetSpaceId(),
      data,
    }
  }
})
