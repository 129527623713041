import { useCookies } from "vue3-cookies";

const {cookies} = useCookies();

/**
 * provide cookie service
 */
class CookieService {
  save(key: string, data: any, expired : any = undefined) {
    if (data instanceof Object) {
        cookies.set(key, JSON.stringify(data), expired, "/", process.env.VUE_APP_DOMAIN)
    } else {
      cookies.set(key, data, expired, "/", process.env.VUE_APP_DOMAIN)
    }
  }

  remove(key) {
    cookies.remove(key)
  }

  get(key: string) {
    return cookies.get(key)
  }
}

export default new CookieService();