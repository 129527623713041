
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {useI18n} from "vue-i18n/index";
import {useRoute} from "vue-router";
import {version} from "@/core/helpers/documentation";
import {asideMenuIcons} from "@/core/helpers/config";
import {DocLibMenu, MetricMenu, TaskMenu, TimerMenu, WorkMenu} from '@/core/config/MainMenuConfig';
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {getCurrentSpaceAccess, GetSpaceId} from "@/core/composite/composite";
import {useGroupStore} from "@/store/group.store";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const {t, te} = useI18n();
    const route = useRoute();
    const store = useStore();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const menuData = computed(() => store.state.DocModule.menuData)
    store.dispatch(Actions.LOAD_MENU);
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    const state = reactive({
      showPanel: false,
      title: '',
      model: {name: '', type: '', body: '', parentId: '', description: ''}
    });
    const submitting = ref(false);
    const groupStore = useGroupStore();
    groupStore.getDashboards();
    const dashboards = computed(() => groupStore.dashboards);
    return {
      dashboards,
      submitting,
      menuData,
      state,
      hasActiveChildren,
      MetricMenu,
      WorkMenu,
      TaskMenu,
      TimerMenu,
      DocLibMenu,
      asideMenuIcons,
      store,
      version,
      translate,
      ...getCurrentSpaceAccess(),
      ...GetSpaceId(),
    };
  },
})
