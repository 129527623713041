import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskName = _resolveComponent("TaskName")!
  const _component_TaskCompleteStatus = _resolveComponent("TaskCompleteStatus")!
  const _component_TaskDueDate = _resolveComponent("TaskDueDate")!
  const _component_DateTimeFormat = _resolveComponent("DateTimeFormat")!
  const _component_KtDatatable = _resolveComponent("KtDatatable")!

  return (_openBlock(), _createBlock(_component_KtDatatable, {
    "table-data": _ctx.page.data,
    "table-header": _ctx.headers,
    "enable-items-per-page-dropdown": true,
    "current-page": _ctx.filter.paginationPage,
    "rows-per-page": _ctx.filter.paginationSize,
    onItemsPerPageChange: _ctx.handleUpdateSize,
    onCurrentChange: _ctx.handleUpdatePage,
    total: _ctx.page.total
  }, {
    "cell-name": _withCtx(({ row: row }) => [
      _createVNode(_component_TaskName, { task: row }, null, 8, ["task"])
    ]),
    "cell-status": _withCtx(({row: row}) => [
      _createVNode(_component_TaskCompleteStatus, {
        status: row.completeStatus
      }, null, 8, ["status"])
    ]),
    "cell-dueDate": _withCtx(({row: row}) => [
      _createVNode(_component_TaskDueDate, {
        "due-date": row.dueDate,
        status: row.status
      }, null, 8, ["due-date", "status"])
    ]),
    "cell-completedDt": _withCtx(({row: row}) => [
      _createVNode(_component_DateTimeFormat, {
        date: row.completedDt
      }, null, 8, ["date"])
    ]),
    _: 1
  }, 8, ["table-data", "table-header", "current-page", "rows-per-page", "onItemsPerPageChange", "onCurrentChange", "total"]))
}