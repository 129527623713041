
import {defineComponent} from "vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "MessageLink",
  components: {DateTimeFormat, Avatar},
  props: {
    message: {},
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
