
import {defineComponent} from "vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "HelpPanelRelated",
  props: {
    docs: {}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
