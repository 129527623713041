import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseRadio = _resolveComponent("BaseRadio")!

  return (_openBlock(), _createBlock(_component_BaseRadio, {
    name: _ctx.name,
    data: [
    {value: 1, name:'Yes'},{value: 0, name:'No'}],
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
  }, null, 8, ["name", "modelValue"]))
}