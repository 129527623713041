
import {computed, defineComponent} from "vue";
import {toolbarWidthFluid} from "@/core/helpers/config";
import store from "@/store";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {},
  setup() {
    const user = computed(() => store.getters.currentUser);
    return {
      user,
      toolbarWidthFluid,
    };
  },
});
